<template>
    <div class="relative">
        <div class="w-full absolute block z-50">
            <Header/>
        </div>
        <div class="safety-banner-container space-top w-full">
            <div class="relative h-full w-full flex justify-center flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-8" src="../../../assets/images/safety-standards/safety-banner.png" alt="Safety Standards Banner">
                <div class="flex flex-col z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20 mx-auto">
                    <h1 class="safety-banner-title mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl 
                    text-white-1 flex pr-24 mobileL:pr-0">
                        Safety Standards
                    </h1>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/plc-perks/update-bg.png" alt="">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col mobileL:py-4">
                <div class="text-white-1 md:text-2xl lg:text-3xl xl:text-4xl 2xl:text:5xl px-6 mobileL:px-0 mobileL:w-3/4 py-8 lg:py-12 text-center leading-tight">
                    <p class="font-semibold mb-3 mobileL:mb-8">
                        As your safety remains our utmost priority, we highly encourage you to book an 
                        appointment via call or text before heading to our branches.
                    </p>
                    <p>We've also implemented new store protocols to ensure the safety and protection of all, 
                        please be advised as we take care of you and your hair-removal needs.</p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/about-us/body-bg.png" alt="Background Image">
            <div class="relative">
                <div class="relative flex mobileL:flex-row flex-col-reverse items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">   
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-left">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">BE ON TIME</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            To avoid cancellation of your appointment, please arrive at least 15 minutes before your schedule
                        </p>
                    </div>
                    <img class="mobileL:mr-4 ml-4 mobileL:ml-0 w-1/2 mobileL:w-1/6 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/be on time.png" alt="History">
                </div>
                <div class="relative flex mobileL:flex-row flex-col items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">
                    <img class="mobileL:mr-4 mr-4 w-1/2 mobileL:w-1/6 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/keep a safe distance.png" alt="History">
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-right">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">KEEP A SAFE DISTANCE</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            To maintain proper social distancing, we're imposing a strict 
                            "no companion" policy in our salons. 
                            A maximum of 2 people are allowed to stay in our reception areas.
                        </p>
                    </div>
                </div>
                <div class="relative flex mobileL:flex-row flex-col-reverse items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">   
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-left">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">CLIENT DECLARATION</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            We'll also be asking our guest to declare medical and travel 
                            history and we'll be doing a temperature check too 
                            just to be extra sure! If clients are indicative of any symptoms 
                            or fail the temperature check, 
                            they will not be permitted to enter our salons.
                        </p>
                    </div>
                    <img class="mobileL:mr-4 ml-4 mobileL:ml-0 w-1/2 mobileL:w-1/6 my-2 mobileL:my-12" src="../../../assets/images/safety-standards/client declaration.png" alt="History">
                </div>
                <div class="relative flex mobileL:flex-row flex-col items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">
                    <img class="mobileL:mr-4 mr-4 w-1/2 mobileL:w-1/6 my-2 mobileL:my-12" src="../../../assets/images/safety-standards/no mask no entry.png" alt="History">
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-right">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">NO MASK NO ENTRY</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            Clients are required to wear face masks while in our store premises.
                        </p>
                    </div>
                </div>
                <div class="relative flex mobileL:flex-row flex-col-reverse items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">   
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-left">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">STRICT SANITATION</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            Of course, we will be implementing strict hygiene 
                            standards when it comes to the sanition and 
                            cleaning practices of our stores to make it virus-free and extra safe.
                        </p>
                    </div>
                    <img class="mobileL:mr-4 ml-4 mobileL:ml-0 w-1/2 mobileL:w-1/6 my-2 mobileL:my-12" src="../../../assets/images/safety-standards/strict sanitation.png" alt="History">
                </div>
                <div class="relative flex mobileL:flex-row flex-col items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">
                    <img class="mobileL:mr-4 mr-4 w-1/2 mobileL:w-1/6 my-2 mobileL:my-12" src="../../../assets/images/safety-standards/cashless payments.png" alt="History">
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-right">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">CASHLESS PAYMENT ARE WELCOME</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            We've also equipped some of our branches to access cashless 
                            payments to lessen contact and make the experience more hassle-free.
                        </p>
                    </div>
                </div>
                <div class="relative flex mobileL:flex-row flex-col-reverse items-center justify-center gap-0 mobileL:gap-12 mb-4 mobileL:mb-0">   
                    <div class="w-4/6 text-brown-4 text-center mobileL:text-left">
                        <h2 class="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-0 mobileL:mb-4">OUR STAFF IS READY TO SERVE YOU</h2>
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                            They will be in PPEs, complete with face masks, shields, aprons, 
                            gloves to make sure they're more than ready to provide the 
                            expert care to our clients. FYI, we are also operating 
                            with minimal staffing so please bear with us.
                        </p>
                    </div>
                    <img class="mobileL:mr-4 mr-4 w-1/2 mobileL:w-1/6 my-2 mobileL:my-12" src="../../../assets/images/safety-standards/our staff.png" alt="History">
                </div>
                <div class="mt-6 lg:mt-8 pb-4 md:pb-6 lg:pb-8 text-center mx-12 mobileL:mx-0">
                    <h2 class="font-tahu text-brown-4 text-4xl md:text-4xl lg:text-7xl 2xl:text-9xl">
                        We're excited to see you back in our branches!
                    </h2>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
  components: { Header, Footer },
  mounted() {
    this.$gtag.pageview('/laybare/safety-standards')
  },
}
</script>

<style>
.safety-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .safety-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .safety-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .safety-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.safety-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .safety-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
}

@media only screen and (min-width: 992px) {
    .safety-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
}

@media only screen and (min-width: 1200px) {
    .safety-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
}
</style>
